<template>
  <el-container>
    <el-main>
      <div class="block">
        <span class="demonstration"><strong>Example: Changes in the territory of the United States of America</strong></span>
        <p>The <strong>United States of America</strong> was founded in 1776 when thirteen British colonies declared their independence through the adoption of the Declaration of Independence on July 4th. The document, primarily authored by Thomas Jefferson, proclaimed the colonies' right to self-governance and their separation from British rule, marking the birth of a new nation.
        The Revolutionary War (1775–1783) followed, during which the colonies, with the aid of allies such as France, secured victory against Great Britain. The Treaty of Paris, signed in 1783, officially recognized the sovereignty of the United States.
        In 1787, the U.S. Constitution was drafted, creating a federal system of government with a balance of powers among the executive, legislative, and judicial branches. Ratified in 1788, it remains the supreme law of the land. George Washington became the first President in 1789.
        The 19th century saw significant territorial expansion through purchases, treaties, and conflicts, including the Louisiana Purchase (1803) and the annexation of Texas (1845). However, tensions over slavery culminated in the Civil War (1861–1865), which ended with the abolition of slavery and the preservation of the Union.</p>
        <el-slider v-model="cYear" :step="1" :min="min" :max="max" :format-tooltip="formatTooltip" @change="changeYear"></el-slider>
      </div>
      <div id="ttmap">
        <div id="tmap"></div>
      </div>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link>
        <br/>More subject are in the process. You can send an email to the above email address
      </el-footer>
  </el-container>
</template>
<script>
/* eslint-disable */
import navBar from '../components/NavBar.vue'
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
// import 'mapbox-gl/dist/mapbox-gl.css'
import "mapbox-gl-style-switcher/styles.css"
import { Config } from '../config/config.js'
export default {
  data() {
    return {
      cYear: 1776,
      max: 1900,
      min: 1776,
      zoom: 1.5,
      minZoom: 0,
      maxZoom: 9,
      map: null,
    }
  },
  components: {
    navBar
  },
  methods: {
    formatTooltip(val) {
      return 'AD' + val;
    }
    , changeYear(y) {
      console.log(y)
      this.cYear = y
      for(let i=1776; i <= y-1;i++){
        this.map.setLayoutProperty("history_american_"+i,'visibility','none')
        this.map.setLayoutProperty("history_american_"+i,'visibility','none')
      }
      for(let i=y+1; i <= 1900;i++){
        this.map.setLayoutProperty("history_american_"+i,'visibility','none')
        this.map.setLayoutProperty("history_american_"+i,'visibility','none')
      }
      this.map.setLayoutProperty("history_american_"+y,'visibility','visible')
      this.map.setLayoutProperty("history_american_"+y,'visibility','visible')

    }, format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + year
      }
    },
  },
  mounted: async function () {
    let year = this.format(this.currentYear)
    let { data } = await this.$http.get(Config.host + '/api/tile/style/' + 'AD1776')
    this.style = data
    console.log(data)
    data['sources']['american'] = { 'tiles': [Config.host+"/tile/data/american/{z}/{x}/{y}.pbf"], type: "vector" }
    this.map = new mapboxgl.Map({
      container: "tmap",
      style: data,
      pitchWithRotate: false,
      dragRotate: false,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      center: [-50, 40],
      preserveDrawingBuffer: true
    });
    this.map._authenticate = function () { };
    this.map.on("style.load", () => {
      this.map.setFog({});
      for (let year = 1776; year <= 1900; year++) {
        this.map.addLayer(
          {
            "minzoom": 0,
            "layout": {
              "visibility": "none"
            },
            "paint": {
              "fill-color": '#ff0000',
              "fill-opacity": 0.5
            },
            "filter": [
              "!=",
              "color",
              ""
            ],
            "id": "history_american_" + year,
            "source": "american",
            "source-layer": "history_american_" + year,
            "maxzoom": 9,
            "type": "fill"
          }
        )
        this.map.addLayer(
          {
            "minzoom": 0,
            "layout": { "visibility": "none" },
            "paint": { "line-opacity": 0.7, "line-color": "#ff0000", "line-width": 1 },
            "id": "history_american_line" + year,
            "source": "american",
            "source-layer": "history_american_" + year,
            "maxzoom": 9,
            "type": "line"
          }
        )

      }
      this.map.setLayoutProperty("history_american_" + 1776, 'visibility', 'visible')
      this.map.setLayoutProperty("history_american_line" + 1776, 'visibility', 'visible')

    });
    this.map.style.glyphManager.localGlyphMode = 0;
  }
}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

p {
  text-align: left;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

.el-card {
  background-color: #f1e9d2;
}

#ttmap {
  width: 100%;
  height: 70%;
}

#tmap {
  width: 100%;
  height: 100%;
}
</style>
