<template>
  <el-container>
    <div class="login-wrapper" v-show="isLogin && !showRegist">
      <div class="login-wrapper-header">Login</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm">
        <el-form-item  prop="username">
          <el-input v-model="ruleForm.username" placeholder="username"></el-input>
        </el-form-item>
        <el-form-item  prop="password">
          <el-input v-model="ruleForm.password" show-password placeholder="password"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn" @click="submitForm('ruleForm')">Login</div>
      <div class="msg">
          Don't have account?
          <a  @click="toRegist" :style="{'cursor':'pointer'}">Sign up</a>
      </div>
      <div class="msg">
        <img src="@/assets/github.png" @click="go('1')" class="github" />
      </div>
    </div>
    <div class="login-wrapper" v-show="!isLogin">
      logining...
    </div>
    <div class="login-wrapper" v-show="showRegist">
      <div class="login-wrapper-header">Sign up</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRegist"  class="demo-ruleForm">
        <el-form-item  prop="username">
          <el-input v-model="ruleForm.username" placeholder="username"></el-input>
        </el-form-item>
        <el-form-item  prop="password">
          <el-input v-model="ruleForm.password" show-password placeholder="password"></el-input>
        </el-form-item>
        <el-form-item  prop="confirmPassword">
          <el-input v-model="ruleForm.confirmPassword" show-password placeholder="confirmPassword"></el-input>
        </el-form-item>
        <el-form-item  prop="email">
          <el-input v-model="ruleForm.email" placeholder="email"></el-input>
        </el-form-item>
        <el-form-item  prop="captcha">
          <el-input v-model="ruleForm.captcha" placeholder="captcha">
            <template slot="append"><div style="width: 100px;height: 35px;"><img @click="refreshCaptcha" ref="captchaImg" :src="captchaImg" :border="0" style="width: 100%;object-fit: cover;cursor: pointer;"></div></template>
          </el-input>
        </el-form-item>
        <el-row>
          <el-button type="primary" @click="regist('ruleFormRegist')">sign up</el-button>
          <el-button type="info" @click="resetForm('ruleFormRegist')">reset</el-button>
        </el-row>
      </el-form>
      <div class="msg">
        <img src="@/assets/github.png" @click="go('1')" class="github" />
      </div>
    </div>
  </el-container>
</template>
<script>
/* eslint-disable */
import { Config } from '../config/config.js'
export default {
  data() {
    return {
      isLogin: true,
      showRegist: false,
      captchaImg : '',
      ruleForm: {
        username: '',
        password: '',
        confirmPassword:'',
        captcha: ''
      },
      rules: {
        username: [
          { required: true, message: 'input username', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'input password', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'input confirmPassword', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'input email', trigger: 'blur' },
          {pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,message:'email format error',trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: 'input captcha', trigger: 'blur' }
        ]
      },
      host: Config.host
    }
  },
  components: {

  },
  mounted: async function () {

    let { data } = await this.$http.get(Config.host + '/api/captcha/image?type=char' )
    this.captchaImg = data['data']
    if (window.location.search.split("code=").length > 1) {
      let code = window.location.search.split("code=")[1]
      console.log(code)
      this.isLogin = false
      let { data } = await this.$http.get(Config.host + '/api/login/github?code=' + code)
      console.log(data)
      localStorage.setItem('auth', data['data'])
      let { data: res } = await this.$http.get(Config.host + '/api/user/info')
      console.log(res)
      localStorage.setItem('user', JSON.stringify(res['data']))
      window.location.href = Config.fehost +'#/user'
    }
  },
  methods: {
    go(type) {
      if (type == '1') {
        const query = new URLSearchParams({
          loginType: 1,
          client_id: 'Ov23lirYrq8LnlEYb7OH',
          scope: '',
          allow_signup: 'true',
          redirect_uri: Config.fehost+'/#/login'
        })
        const url = `https://github.com/login/oauth/authorize?${query.toString()}`
        window.location.href = url
      }
    },
    submitForm(formName) {
      let that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(that.ruleForm.username)
          console.log(that.ruleForm.password)
          let { data } = await that.$http.post(Config.host + '/api/user/login', { "username": that.ruleForm.username, "password": that.ruleForm.password })
          console.log(data)
          if (data['code'] == 200) {
            localStorage.setItem('auth', data['data'])
            let { data: res } = await that.$http.get(Config.host + '/api/user/info')
            console.log(res)
            if (res['code'] == 200) {
              localStorage.setItem('user', JSON.stringify(res['data']))
              window.location.href = Config.fehost +'#/user'
            } else {
              that.$message(res['message']);
            }
          } else {
            that.$message(data['message']);
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toRegist(){
      this.showRegist = true
    },
    regist(formName) {
      let that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(that.ruleForm.username)
          console.log(that.ruleForm.password)
          console.log(that.ruleForm.confirmPassword)
          if (that.ruleForm.password!==that.ruleForm.confirmPassword){
            this.$message.error("confirmPassword error")
            return false;
          }
          let { data } = await that.$http.post(Config.host + '/api/user/regist', { "username": that.ruleForm.username, "password": that.ruleForm.password , 
          "email": that.ruleForm.email,
          "captcha": that.ruleForm.captcha,
          })
          console.log(data)
          if (data['code'] == 200) { 
            that.$message(data['message']);
            this.showRegist = false
          } else {
            that.$message(data['message']);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async refreshCaptcha(){
      this.$refs['captchaImg'].src=this.host + '/api/captcha/image?type=char&r=' +Math.random()
      let { data } = await this.$http.get(Config.host + '/api/captcha/image?type=char' )
      this.captchaImg = data['data']
    }
  }
}

</script>
<style scoped>
.el-container {
  height: 100%;
  background-image: linear-gradient(to right, #b4a9b1, #a6c1ee);
}
.login-wrapper {
  background-color: #fff;
  width: 358px;
  height: 588px;
  border-radius: 15px;
  padding: 0 50px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-wrapper-header {
            font-size: 38px;
            font-weight: bold;
            text-align: center;
            line-height: 110px;
        }
.btn {
    text-align: center;
    padding: 10px;
    margin-top: 40px;
    background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
    color: #fff;
}
.msg {
    text-align: center;
    line-height: 88px;
}
a {
    text-decoration-line: none;
    color: #abc1ee;
}      

.github {
  /* background-image: url('@/assets/github.png'); */
  width: 20px;
  height: 20px;
  cursor: pointer;
}
::v-deep .el-input-group__append{
  padding: 0 ;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
