<template>
  <el-container>
    <el-header>
          <navBar cIndex="/subject"></navBar>
    </el-header>
    <el-main>
      <div>
      <el-link type="info" @click="goto('ottoman')"> <span>Changes in the territory of the Ottoman Empire from 1299AD to 1899AD</span></el-link>
      <el-divider></el-divider>
      <el-link type="info" @click="goto('usa')"> <span>Changes in the territory of the  United States of America from 1776AD to 1900AD</span></el-link>
      <el-divider></el-divider>
    </div>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link>
        <br/>More subject are in the process. You can send an email to the above email address
      </el-footer>
  </el-container>
</template>
<script>
import navBar from '../components/NavBar.vue'
export default {
  data () {
    return {}
  },
  components: {
    navBar
  },
  methods: {
    goto (path) {
      this.$router.push(path)
    }
  }
}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

p {
  text-align: left;
}
.el-header {
  padding: 0;
  background-color: #f1e9d2;
}
.el-card{
  background-color: #f1e9d2;
}
</style>
