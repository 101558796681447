<template>
  <el-container>
    <el-header>
      <navBar cIndex="/donate"></navBar>
    </el-header>
    <el-main>
      <h3>【Thank you for visiting my non-profit mapping website! 】</h3>
      <p>This project is independently supported by the
        author（@daoyuan997）, with the goal of providing users with a better mapping service. The immense effort required
        to maintain such a large mapping website and to develop new features for the project can only be sustained through
        the generous financial support of our sponsors.</p>

      <h3>【Purpose of Donations】</h3>
      <p>Your contributions will be used for the following purposes:
        Server Maintenance: Ensuring the website runs smoothly and provides a fast browsing experience.
        Technical Upgrades: Continuously improving website features to meet user needs.
        Content Updates: Regularly updating map data to ensure accuracy and timeliness.</p>

      <h3>【Making the Product Better】</h3>
      <p> Every donation directly impacts the quality of our product. Your support will help us:
        Enhance user experience
        Add more useful features
        Expand the coverage of map data</p>

      <p><strong>Thank you for your generous support!</strong> Together, we can make this mapping product even better!</p>
      <div class="pay-area">
        <div>
          <el-input placeholder="Email(optional)" v-model="email">
            <template slot="prepend">Email:</template>
          </el-input>
        </div>
        <div class="amount-area">
        <el-radio-group v-model="amount" @change="amountChange">
          <img src="@/assets/images5.png" class="a5"/><el-radio :label="4.9" border size="medium">$4.9</el-radio>
          <img src="@/assets/images10.png" class="a5"/><el-radio :label="9.9" border size="medium">$9.9</el-radio>
          <img src="@/assets/images20.png" class="a5"/><el-radio :label="19.9" border size="medium">$19.9</el-radio>
        </el-radio-group>
      </div>
        <div id="paypal-button-container_5" v-show="show5"></div>
        <div id="paypal-button-container_10" v-show="show10"></div>
        <div id="paypal-button-container_20" v-show="show20"></div>
      </div>
      <div class="pay-area-m">
        <div>
          <el-input placeholder="Email(optional)" v-model="email">
            <template slot="prepend">Email:</template>
          </el-input>
        </div>
        <el-radio-group v-model="amount" @change="amountChange">
          <el-radio :label="4.9">$4.9</el-radio>
          <el-radio :label="9.9">$9.9</el-radio>
          <el-radio :label="19.9">$19.9</el-radio>
        </el-radio-group>
        <div id="paypal-button-container_m_5" v-show="show5"></div>
        <div id="paypal-button-container_m_10" v-show="show10"></div>
        <div id="paypal-button-container_m_20" v-show="show20"></div>
      </div>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link></el-footer>
  </el-container>
</template>
<script>
/* eslint-disable */
import navBar from '../components/NavBar.vue'
import { Config } from '../config/config.js'
import { loadScript } from "@paypal/paypal-js"
import { nanoid } from 'nanoid'

let paypal;
try {
  paypal = await loadScript({ clientId: Config.paypalkey ,locale: "en_US"});
} catch (error) {
  console.error("failed to load the PayPal JS SDK script", error);
}

export default {
  data() {
    return {
      amount: 9.9,
      email: '',
      show5: false,
      show10: true,
      show20: false,
    }
  },
  components: {
    navBar
  },
  mounted() {
    this.renderPayButton(4.9, '#paypal-button-container_5')
    this.renderPayButton(9.9, '#paypal-button-container_10')
    this.renderPayButton(19.9, '#paypal-button-container_20')
    this.renderPayButton(4.9, '#paypal-button-container_m_5')
    this.renderPayButton(9.9, '#paypal-button-container_m_10')
    this.renderPayButton(19.9, '#paypal-button-container_m_20')
  },
  methods: {
    amountChange(e) {
      console.log(e)
      if (e == 9.9) {
        this.show10 = true
        this.show5 = false
        this.show20 = false
      } else if (e == 19.9) {
        this.show20 = true
        this.show10 = false
        this.show5 = false
      } else {
        this.show20 = false
        this.show10 = false
        this.show5 = true
      }
    },
    renderPayButton(amount, ele) {
      let that = this

      paypal
        .Buttons({
          style: {
            shape: "rect",
            layout: "vertical",
            color: "gold",
            label: "paypal",
          },
          message: {
            amount: amount,
          },

          async createOrder() {
            let { data } = await that.$http.post(Config.host + '/api/donate/createOrder', {
              orderId: nanoid(),
              email: that.email,
              amount: amount
            })
            return data['data']['id']
          },
          async onApprove(data, actions) {
            console.log(data)
            console.log(actions)
            let { data: res } = await that.$http.post(Config.host + "/api/donate/capture/" + data.orderID)
            const orderData = res['data']
            const errorDetail = orderData?.details?.[0];
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
              return actions.restart();
            } else if (errorDetail) {
              throw new Error(
                `${errorDetail.description} (${orderData.debug_id})`
              );

              that.$message({
                  type: 'error',
                  message: 'network error!'
                });

            } else if (!orderData.purchase_units) {
              that.$message({
                  type: 'error',
                  message: JSON.stringify(orderData)
                });
            } else {
              const transaction =orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
              console.log("Capture result", orderData, JSON.stringify(orderData, null, 2));
              that.$message({
                  type: 'success',
                  message: 'Thank you for your donation '
                });
              // handler success ---save user email
            }
          },
        })
        .render(ele);
    }

  }

}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

.pay-area {
  position: relative;
  /* 绝对定位 */
  top: 0%;
  width: 500px;
  left: 50%;
  transform: translate3d(-50%, 10%, 0);
  padding: 20px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #fff;
  transition: transform 0.3;
}
.el-radio-group{
  margin: 10px;
}
.el-radio__inner{
  width: 25px;
  height: 25px;
  zoom: 25px;
}
.amount-area{
    height: 58px;
    display: flex;
  justify-content: center;
}
.a5{
  width: 25px;
  height: 25px;
  margin-right: 10px;
  line-height: 58px;
}

.pay-area-m {}

@media screen and (max-width: 960px) {
  .pay-area {
    display: none;
    height: 0;
  }
}

@media screen and (min-width: 960px) {
  .pay-area-m {
    display: none;
  }
}
</style>
