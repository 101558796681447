<template>
  <div id="mapp">
    <div class="tool-box">
      <div title="subject" class="tool-box-item"><button class="tool-box-item-btn" @click="handleCommand('ottoman')"><i
            class="el-icon-place"></i></button></div>
      <div title="compare" class="tool-box-item"><button class="tool-box-item-btn" @click="handleCommand('map-cmp')"><i
            class="fa fa-columns"></i></button></div>
      <div title="donate" class="tool-box-item">
        <p class="tool-box-item-btn paypal-logo-long" @click="handleCommand('donate')"></p>
      </div>
    </div>
    <div id="map"></div>
    <virtual-ruler id="rule" ref="ruler" top-border :height="50" :min=-3200 :max="1900" :default-value="currentYear"
      :gap="10" :value-gap="1" @change="ruleChange">
    </virtual-ruler>
    <div class="year-input">
      <el-input-number v-model="currentYear" @change="changeYear" :min="minYear" :max="maxYear" :step="step"
        :step-strictly="true" label="currentYear"></el-input-number>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" :modal=false custom-class="info-draw" :size="640">
      <div slot="title"><strong>{{ info.title_en }}</strong></div>
      <div class="des_area info-text test-scroller"> {{ info.text }}</div>
      <div class="comment_area test-scroller">
        <div class="comment-title"><span class="comment-title-text">Comment</span>
          <div class="edit-button" @click="loadMoreComments"><img src="@/assets/edit-contained.png"
              style="width: 16px;height: 16px;padding-left: 14px;vertical-align: middle;" /> comment</div>
        </div>
        <div class="comment-container " v-show="commentList.length > 0">
          <div class="comment" v-for="comment in commentList" :key="comment.id">
            <div class="comment-header">
              <span class="username">{{ comment.username }}</span>
              <span class="time">{{ comment.createTime }}</span>
            </div>
            <div class="comment-body">
              {{ comment.comment }}
            </div>
          </div>
        </div>
      </div>
      <el-drawer title="Comment" :direction="direction" :append-to-body="true" :visible.sync="innerDrawer" :size="640">
        <div class="comment_area_list  test-scroller">
          <div class="comment-container test-scroller" v-show="pageComment.length > 0" v-infinite-scroll="loadData"
            infinite-scroll-delay="200">
            <div class="comment" v-for="comment in pageComment" :key="comment.id">
              <div class="comment-header">
                <span class="username">{{ comment.username }}</span>
                <span class="time">{{ comment.createTime }}</span>
              </div>
              <div class="comment-body">
                {{ comment.comment }}
              </div>
            </div>
          </div>
          <div class="comment-input">
            <el-input type="textarea" :rows="2" placeholder="comment" v-model="userComment">
            </el-input>
            <div class="more-button-container">
              <div id="submitComment" class="load-more-button" @click="submitComment">submit</div>
            </div>
          </div>
        </div>
      </el-drawer>
    </el-drawer>
    <div class="search">
      <el-autocomplete class="inline-input" v-model="keyword" :fetch-suggestions="querySearch" placeholder="Roman Empire"
        :trigger-on-focus="false" @select="handleSelect">

        <el-dropdown slot="prepend" size="medium" @command="handleCommand">
          <el-button type="primary">
            <i class="el-icon-s-operation"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/">Home</el-dropdown-item>
            <el-dropdown-item command="map-cmp">Compare</el-dropdown-item>
            <el-dropdown-item command="3d">3D</el-dropdown-item>
            <el-dropdown-item command="subject">Subject</el-dropdown-item>
            <el-dropdown-item command="donate">Donate</el-dropdown-item>
            <el-dropdown-item command="about">About</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-autocomplete>
      <el-button type="info" icon="el-icon-chat-dot-round" @click="aiDialogVisible = true"></el-button>
    </div>
    <el-dialog title="ask ai" :visible.sync="aiDialogVisible" width="30%">
      <el-input placeholder="submit you question（eg:The spread of corn）" v-model="aiText"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="aiDialogVisible = false">cancel</el-button>
        <el-button type="primary" @click="renderAI">submit</el-button>
      </span>
    </el-dialog>
    <div class="timeline-area test-scroller" :class="{collapse:collapseTimeState}"  v-show="timelineShow">
      <div class="timeline-tool "><div class="ai-tips">Generate by ai <div class="loader" v-show="loadingShow"></div></div><div @click="collapseTimeline" >{{collapseTimeState?'expand':'collapse'}}</div> </div>
      <div class="timeline-summary">{{ aiResdata?.summary }}</div>
      <div class="timeline-container ">
        <div class="timeline-item" v-for="(item,index) in aiResdata?.timeline" :key="item.index" @click="focusPoint(index)">
          <div class="timeline-header">
            <span class="timeline-title"><strong> {{ item.year }} {{ item.place }}</strong></span>
          </div>
          <div class="timeline-body">
            {{ item.desc }}
          </div>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
import { CompassControl, StylesControl } from "mapbox-gl-controls";
import "mapbox-gl-style-switcher/styles.css";
import 'virtual-ruler/dist/virtual-ruler.css';
import VirtualRuler from 'virtual-ruler'
import { Config } from '../config/config.js'
import ProjectionControl from '../controls/switchProjectControl'
import { jsonrepair } from 'jsonrepair'
import {EventSourceParserStream} from 'eventsource-parser/stream'
export default {
  data() {
    return {
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
      drawer: false,
      direction: 'ltr',
      defaultYear: 1400,
      minYear: -3200,
      maxYear: 1900,
      currentYear: 1400,
      style: null,
      info: {},
      timeoutId: null,
      ruletimeoutId: null,
      keyword: '',
      step: 1,
      center: [0, 0],
      selectSearch: null,
      userComment: '',
      commentList: [],
      innerDrawer: false,
      pageComment: [],
      page: 1,
      totalPage: 1,
      aiText: '',
      aiDialogVisible: false,
      markers: [],
      lineAnimationInterva: null,
      aiResdata: null,
      collapseTimeState: false,
      aisourceData :{
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": []
            }
          }
        ]
      },
      timelineShow: false,
      loadingShow: false,

    };
  },
  components: {
    VirtualRuler,
  },
  methods: {
    gohome() {
      this.$router.push("/")
    },
    collapseTimeline(){
        this.collapseTimeState = !this.collapseTimeState
    },
    async submitComment() {
      if (!this.userComment) {
        this.$message.error('text is empty')
        return
      }
      let { data } = await this.$http.post(Config.host + '/api/comment/save', { territoryId: this.info.territory_id, comment: this.userComment })
      if (data.code == 200) {
        this.$message.success('save success')
        data.data['username'] = JSON.parse(localStorage.getItem('user')).username
        this.pageComment.unshift(data.data)
      } else if (data.code == 401) {
        this.$router.push('/login')
      } else {
        this.$message.error('save error')
      }
    },
    async loadMoreComments() {
      this.page = 1
      this.totalPage = 1
      let { data } = await this.$http.post(Config.host + '/api/comment/list', { territoryId: this.info.territory_id, page: this.page, pageSize: 20 })
      this.pageComment = data.data.list
      this.totalPage = data.data.pages
      this.innerDrawer = true
    },
    async loadData() {
      this.page++
      if (this.page > this.totalPage) {
        return
      }
      let { data } = await this.$http.post(Config.host + '/api/comment/list', { territoryId: this.info.territory_id, page: this.page, pageSize: 20 })
      this.pageComment.push(...data.data.list)
    },
    handleCommand(com) {
      if (com == '3d') {
        this.map.setProjection('globe')
      } else {
        this.$router.push(com)
      }
    },
    search() {
      console.log('search')
      if (this.selectSearch) {
        let e = parseInt(this.selectSearch['years'])
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }

    },
    handleSelect(item) {
      console.log(item);
      this.selectSearch = item
    },
    async querySearch(queryString, cb) {
      let { data } = await this.$http.get(Config.host + '/api/tile/search?q=' + queryString)
      let results = data['data'].map(x => {
        return {
          ...x,
          value: x['nameEn'] + "(" + x['years'] + ")"
        }
      })
      cb(results);
    },

    changeYear(e) {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    ruleChange(e) {
      console.log(e)
      if (this.ruletimeoutId !== null) {
        clearTimeout(this.ruletimeoutId);
      }
      this.ruletimeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    getDashArray() {
      let white = 4;
      let black = 6;
      let step = 0.25;
      let arr = [];
      let limit = black / step;
      for (let i = 0; i < (white + black) / step; i++) {
        if (i <= limit) {
          arr.push([step * i, white, black - step * i])
        } else {
          let val = (i - limit) * step;
          arr.push([0, val, black, white - val])
        }
      }
      return arr;
    },
    renderAI() {
      this.aiDialogVisible = false
      let resMsg = '';
      const that= this
      that.timelineShow = true
      that.loadingShow = true
      that.aisourceData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": []
            }
          }
        ]
      }
      that.map.getSource('ai').setData(that.aisourceData)
      fetch(Config.host + '/api/explore/ai/stream', 
                { method: 'POST', 
                  headers: {'Content-Type': 'application/json','auth':localStorage.getItem('auth')?localStorage.getItem('auth'):''},
                  responseType: 'stream',
                  body: JSON.stringify({ prompt: this.aiText }) 
                })
      .then(async response => {
          if (!response.ok) {
              that.$message.error('network error')
          }
          const teedOff = response.body.tee();
          //const reader = response.body.pipeThrough(new TextDecoderStream()).pipeThrough(new EventSourceParserStream()).getReader()
          const reader = teedOff[0].pipeThrough(new TextDecoderStream()).pipeThrough(new EventSourceParserStream()).getReader()
          while (true) {
                const {value, done} = await reader.read();
                if (done) break;
               // console.log(value);
                try {
                  resMsg += value.data
                  that.aiResdata =JSON.parse(jsonrepair(resMsg))
                } catch (error) {
               //   console.log(error)
                }
          }
          if (!resMsg) { // 校验登录和限额
            const textreader = await teedOff[1].pipeThrough(new TextDecoderStream()).getReader()
            while (true) {
                  const {value, done} = await textreader.read();  
                  if (done) break;
                  resMsg += value
            }
            const verifyMsg =JSON.parse(resMsg)
            if (verifyMsg.code == 401) {
              that.$router.push('/login') 
            } else if (verifyMsg.code == 4003) {
              that.$message.error('Request exceeds limit, can be obtained through donation')
              that.timelineShow = false
            }
            return
          }
          
          if (that.markers.length > 0) {
            that.markers.forEach(item => {
              item.remove()
            })
          }
          that.markers = []
          if (!that.aiResdata?.timeline) {
            that.aiResdata = {}
            that.aiResdata.summary= resMsg
            that.loadingShow = false
            return
          }
          that.aiResdata['timeline']?.forEach(item => {
            let point = item.location.slice(0, 2)
            console.log(point)
            if (point.length==1){
              point = point.split(',')
            }
            let tmppoint= point
            if (point[1]<-90 || point[1]>90 ) {
              tmppoint = [point[1],point[0]]
              that.aisourceData.features[0].geometry.coordinates.push([point[1],point[0]])
            }else{
              that.aisourceData.features[0].geometry.coordinates.push([point[0],point[1]])
            }
           
            const popup = new mapboxgl.Popup({ offset: 25 }).setText(
                    item.desc
                );
             const marker =  new mapboxgl.Marker({ draggable: true})
              .setLngLat(tmppoint)
              .setPopup(popup)
              .addTo(that.map)
              marker.on('click', (e) => {
                console.log('marker')
                console.log(e)
              })
            that.markers.push(marker)

          })
          that.map.getSource('ai').setData(that.aisourceData)
          const dashArraySequence = [
          [0, 4, 3],
          [0.5, 4, 2.5],
          [1, 4, 2],
          [1.5, 4, 1.5],
          [2, 4, 1],
          [2.5, 4, 0.5],
          [3, 4, 0],
          [0, 0.5, 3, 3.5],
          [0, 1, 3, 3],
          [0, 1.5, 3, 2.5],
          [0, 2, 3, 2],
          [0, 2.5, 3, 1.5],
          [0, 3, 3, 1],
          [0, 3.5, 3, 0.5]
        ];
        let step = 0;
        function animateDashArray(timestamp) {
          const newStep = parseInt((timestamp / 400) % dashArraySequence.length);
          if (newStep !== step) {
            that.map.setPaintProperty(
              'ai-line-dashed',
              'line-dasharray',
              dashArraySequence[step]
            );
            step = newStep;
          }
          requestAnimationFrame(animateDashArray);
        }
        animateDashArray(0);
        that.loadingShow = false

      })
      .catch(error => {
        console.error('Error:', error)
        that.loadingShow = false
      }
      )
      
    },
    focusPoint(index) {
      console.log(this.aiResdata?.timeline[index])
      let year =this.aiResdata?.timeline[index].year+""
      let yearNum = year.replace(/[^\d]/g, " ").trim().split(/\s+/)[0]
      if(year.indexOf('公元前')>-1 || year.indexOf('BC')>-1||year.indexOf('bc')>-1||year.indexOf('年前')>-1){
        yearNum = - Number(yearNum)
        if (yearNum < -3200) {
          yearNum = -3200
        }
      }else if(year.indexOf('世纪')>-1){
        yearNum =  Number(yearNum)* 100
        if (yearNum>1900) {
          yearNum =1900
        }
      }else{
        yearNum = Number(yearNum)
        if (yearNum>1900) {
          yearNum =1900
        }
      }
      this.currentYear = yearNum
      this.showYear()
      this.map.flyTo({ center: this.aisourceData.features[0].geometry.coordinates[index], zoom: 5})
      let marker = this.markers[index]
      marker.togglePopup()
      this.setMarkerColor(marker,'#ff0000')
      this.markers.forEach((item,markerIndex) => {
        if (markerIndex!=index) {
          this.setMarkerColor(item,'#3FB1CE')
        }
      })
    },
    setMarkerColor(marker,color) {
      let markerElement = marker.getElement();
      markerElement
        .querySelectorAll('svg path')[0]
        .setAttribute("fill", color)
      marker._color = color
    },
    async showYear() {
      let year = this.format(this.currentYear)
      if (localStorage.getItem("zoom")) {
        this.zoom = Number(localStorage.getItem("zoom"))
      }
      if (localStorage.getItem("center")) {
        this.center = JSON.parse(localStorage.getItem("center"))
      }
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      this.style = data
      this.style.fog= Config.fog
      this.map.setStyle(this.style)
      if(this.map.getLayer('ai-line-dashed')){
        this.map.removeLayer('ai-line-dashed')
      }
      if(this.map.getSource('ai')){
        this.map.removeSource('ai')
      }
      this.map.addSource('ai', { 'type': 'geojson', 'data': this.aisourceData });
      this.map.addLayer({
            type: 'line',
            source: 'ai',
            id: 'ai-line-dashed',
            paint: {
              'line-color': 'red',
              'line-width': 3,
              'line-dasharray': [0, 4, 3]
            }
          }
      )

      if (this.selectSearch) {
        this.map.setFilter('country-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.setFilter('country-top-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.setFilter('country-comb-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.panTo(JSON.parse(this.selectSearch['coordinates'])['coordinates'])
      }

    }
  },
  mounted: async function () {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.direction = 'btt'
    }
    if (localStorage.getItem("currentYear")) {
      this.currentYear = Number(localStorage.getItem("currentYear"))
    } else {
      this.currentYear = this.defaultYear
    }
    if (localStorage.getItem("zoom")) {
      this.zoom = Number(localStorage.getItem("zoom"))
    }
    if (localStorage.getItem("center")) {
      this.center = JSON.parse(localStorage.getItem("center"))
    }

    let year = this.format(this.currentYear)
    if (localStorage.getItem("zoom")) {
      this.zoom = Number(localStorage.getItem("zoom"))
    }
    if (localStorage.getItem("center")) {
      this.center = JSON.parse(localStorage.getItem("center"))
    }
    let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
    this.style = data
    this.map = new mapboxgl.Map({
      container: "map",
      style: data,
      pitchWithRotate: false,
      dragRotate: false,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      center: this.center,
      preserveDrawingBuffer: true
    });
    this.map._authenticate = function () { };
    this.map.style.glyphManager.localGlyphMode = 0;
    this.map.addControl(new CompassControl(), "top-right");
    this.map.addControl(new ProjectionControl());
    const that = this
    this.map.on('load', () => {
      if(this.map.getLayer('ai-line-dashed')){
        this.map.removeLayer('ai-line-dashed')
      }
      if(this.map.getSource('ai')){
        this.map.removeSource('ai')
      }
      this.map.addSource('ai', { 'type': 'geojson', 'data': this.aisourceData });
      this.map.addLayer({
            type: 'line',
            source: 'ai',
            id: 'ai-line-dashed',
            paint: {
              'line-color': 'red',
              'line-width': 3,
              'line-dasharray': [0, 4, 3]
            }
          }
      )
      this.map.on('click', 'dls_poi_v1', async (e) => {
        console.log(e);
        e.preventDefault()
        const features = this.map.queryRenderedFeatures(
          e.point,
          { layers: ['dls_poi_v1'] }
        );
        if (features.length > 0) {
          let poiId = features[0].properties['poi_id']
          let { data } = await this.$http.get(Config.host + '/api/poi/info?poiId=' + poiId)
          console.log(data)
          new mapboxgl.Popup({ className: 'popup-class' })
            .setLngLat(e.lngLat)
            .setHTML(`
                <div class="pop-poi" style="height: 550px; overflow: hidden;">
                  <div><h3>${features[0].properties['name_en']} (${data['data']['modernNameEn']})</h3></div>
                  <div style="height: 150px;""><img src =" ${data['data']['imgUrl']}" style="width: 100%;height: 100%;object-fit: cover;" /></div>
                  <div style="height: 330px; overflow-y: scroll; padding" >${data['data']['summaryEn']}</div>
          `)
            .setMaxWidth("300px")
            .addTo(this.map);
        }
      });
      this.map.on('mouseenter', 'dls_poi_v1', (e) => {
        console.log(e);
        this.map.getCanvas().style.cursor = 'pointer'
        e.preventDefault()
      });
      this.map.on('mouseout', 'dls_poi_v1', (e) => {
        console.log(e);
        this.map.getCanvas().style.cursor = ''
        e.preventDefault()
      });

      this.map.on('click', async (e) => {
        if (e.defaultPrevented) return;
        console.log(e.lngLat);
        const features = this.map.queryRenderedFeatures(
          e.point,
          { layers: ['country', 'country_top', 'country_comb_con'] }
        );
        let popup = null
        if (features.length > 0) {
          console.log(features)
          if (features.length == 1) {
            this.map.setFilter('country-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
            this.map.setFilter('country-top-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
            this.map.setFilter('country-comb-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
            this.info = features[0].properties
            let { data } = await this.$http.get(Config.host + '/api/territory/info?territoryId=' + this.info.territory_id)
            console.log(data)
            this.info['text'] = data.data['descriptionEn']
            this.info['title_en'] = data.data['nameEn']
            this.drawer = true
            let { data: commentData } = await this.$http.get(Config.host + '/api/comment/list?territoryId=' + this.info.territory_id)
            this.commentList = commentData['data']

          } else {
            if (popup) return
            let html = '<div><ul class="pop-ul">'
            for (let i = 0; i < features.length; i++) {
              html += '<li class="comb_popup" tid=' + features[i].properties['territory_id'] + '>' + features[i].properties['title_en'] + '</li>'
            }
            html += '</ul></div>'
            popup = new mapboxgl.Popup({ closeButton: false }).setLngLat(e.lngLat).setHTML(html).addTo(this.map);
            let lis = document.querySelectorAll(".pop-ul li");
            for (let index = 0; index < lis.length; index++) {
              const li = lis[index];
              li.onclick = async function () {
                let tid = this.getAttribute('tid')
                that.map.setFilter('country-highlight', ['==', 'territory_id', tid])
                that.map.setFilter('country-top-highlight', ['==', 'territory_id', tid])
                that.map.setFilter('country-comb-highlight', ['==', 'territory_id', tid])
                if (popup){
                  popup.remove();
                }
                let { data } = await that.$http.get(Config.host + '/api/territory/info?territoryId=' + tid)
                console.log(data)
                that.info['title_en'] = data.data['nameEn']
                that.info['text'] = data.data['descriptionEn']
                that.drawer = true
                let { data: commentData } = await that.$http.get(Config.host + '/api/comment/list?territoryId=' + tid)
                this.commentList = commentData['data']
              }
            }
          }

        } else {
          this.drawer = false
          this.map.setFilter('country-highlight', ['==', 'territory_id', ''])
          this.map.setFilter('country-top-highlight', ['==', 'territory_id', ''])
          this.map.setFilter('country-comb-highlight', ['==', 'territory_id', ''])
        }

      });
      this.map.on('mouseenter', 'country_top', (e) => {
        const features = this.map.queryRenderedFeatures(
          e.point,
          { layers: ['country_top',] }
        );
        if (features.length > 0) {
          console.log(features)
          this.map.setFilter('country_top_hover', ['==', 'territory_id', features[0].properties['territory_id']])
        }
      });
      this.map.on('mouseleave', 'country_top', () => {
        this.map.setFilter('country_top_hover', ['==', 'territory_id', ''])
      });

      this.map.on('mouseenter', 'country', (e) => {
        const features = this.map.queryRenderedFeatures(
          e.point,
          { layers: ['country',] }
        );
        if (features.length > 0) {
          this.map.setFilter('countryborder_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          this.map.setFilter('country_hover', ['==', 'territory_id', features[0].properties['territory_id']])
        }
      });
      this.map.on('mouseleave', 'country', () => {
        this.map.setFilter('countryborder_hover', ['==', 'territory_id', ''])
        this.map.setFilter('country_hover', ['==', 'territory_id', ''])
      });
      this.map.on('mouseenter', 'country_comb_con', (e) => {
        const features = this.map.queryRenderedFeatures(
          e.point,
          { layers: ['country_comb_con',] }
        );
        if (features.length > 0) {
          this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', features[0].properties['territory_id']])
        }
      });
      this.map.on('mouseleave', 'country_comb_con', () => {
        this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', ''])
      });
      this.map.on('zoomend', () => {
        localStorage.setItem('zoom', this.map.getZoom())
      });
      this.map.on('moveend', () => {
        localStorage.setItem('center', "[" + this.map.getCenter().lng + "," + map.getCenter().lat + "]")
      });
      this.map.setLayoutProperty('point-level-5', 'text-line-height', 0.9)
      this.map.setLayoutProperty('point-level-4', 'text-line-height', 0.9)
      this.map.setLayoutProperty('point-level-3', 'text-line-height', 0.9)
      this.map.setLayoutProperty('point-level-2', 'text-line-height', 0.9)
      this.map.setLayoutProperty('point-level-1', 'text-line-height', 0.9)
      if (this.selectSearch) {
        this.map.setFilter('country-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.setFilter('country-top-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.setFilter('country-comb-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
        this.map.panTo(JSON.parse(this.selectSearch['coordinates'])['coordinates'])
      }
      window.map = this.map;

    }
    )

  },
  watch: {
    currentYear(newVal, oldVal) {
      console.log(newVal)
      localStorage.setItem("currentYear", newVal)
      this.$refs.ruler.setValue(newVal)
    }
  }
};
</script>
<style scoped >
::v-deep .el-drawer {
  background-color: #EEE6D0 !important
}

::v-deep .el-drawer__header {
  margin-bottom: 7px;
  background-color: #EEE6D0 !important;
  text-align: left !important;
  padding: 11px 40px 0px 40px;
  color: #000000;
}

#mapp {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}


#rule {
  position: fixed;
  bottom: 0;
  left: 0;
}

.year-input {
  position: fixed;
  bottom: 50px;
  -webkit-appearance: none;
  left: 50%;
  transform: translateX(-50%);
}

.info-text {
  padding: 10px 40px;
  height: 480px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  text-transform: none;

}

.test-scroller::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

/* Add a thumb */
.test-scroller::-webkit-scrollbar-thumb {
  background: #000;
}

.des_area {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.comment_area {
  height: 440px;
  overflow-y: auto;
  text-align: left;
  color: #354141;
  padding: 0 40px;
  /* background-color: #F4EEDE; */
}

.comment_area_list {
  height: 80%;
  overflow-y: auto;
  text-align: left;
  color: #354141;
  padding: 0 40px;
  /* background-color: #F4EEDE; */
}

.comment-title {
  line-height: 32px;
  padding-left: 5px;
  border-bottom: 1px solid #E3DCC7;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.comment-title-text {
  width: 74px;
  height: 22px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  text-transform: none
}

.edit-button {
  width: 148px;
  height: 32px;
  background: #F44444;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 29px;
  text-align: left;
  margin: 0px 9px;
  cursor: pointer;
}

.comment-container {
  padding: 2px;
}

.comment {
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.comment:last-child {
  border-bottom: none;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #333;
}

.username {
  width: 70px;
  height: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.time {
  width: 123px;
  height: 17px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 14px;
  text-align: right;
  font-style: normal;
  text-transform: none;
}

.comment-body {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.more-button-container {
  text-align: center;
  margin-top: 10px;
}

.load-more-button {
  width: 78px;
  height: 32px;
  background: #F44444;
  border-radius: 35px 35px 35px 35px;
  cursor: pointer;
  float: right;
  line-height: 32px;
  color: #FFFFFF;
}

.load-more-button:hover {
  background-color: #ec5527;
}

.comment-input {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 80%;
  padding: 0px 40px;
}

::v-deep .el-textarea {
  padding: 0px 0px;
}

.search {
  position: fixed;
  top: 22px;
  left: 10px;
}

li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.el-dropdown {
  vertical-align: top;
}

::v-deep .tool-box {
  position: fixed;
  top: 84px;
  right: 0px;
  z-index: 999;
  padding: 2px 2px;
}

.tool-box-item {}

.tool-box-item-btn {
  width: 31px;
  height: 31px;
  margin: 7px 7px 0 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
  border-radius: 20%;
}

.paypal-logo-long {
  background: transparent url('@/assets/paypal-mark-color.svg') top center no-repeat;
  display: block;
}

.timeline-area {
  position: absolute;
  top: 130px;
  left: 50px;
  text-align: left;
  height: auto;
  max-height: 70%;
  width: 25%;
  overflow-y: auto;
  padding: 0px 10px 20px 10px;
  background-color: #f9f9f9;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease-out;
}
.timeline-area.collapse{
  height: 50px;
  overflow-y: hidden;
}
.timeline-tool{
  position: sticky;
  top: 0px;
  background-color: #f9f9f9;
  height: 60px;
  margin: 0 10px;
  padding: 0;
  display: flex;
  line-height: 60px;
  justify-content: space-between;
}
.timeline-tool div{
  cursor: pointer;
}
.timeline-summary{
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}
.timeline-container{
  height: auto;
  margin-top: 10px;
  max-height: 70%;
  overflow-y: auto;
}
.timeline-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.ai-tips{
  color: #bbc2c4d8;
  font-size: 12px;
}
.loader {
    border: 1px solid #f3f3f3;
    border-top: 2px solid #555;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    display: inline-block;
    animation: spin 2s linear infinite;
    line-height: 60px;
    margin-left: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.focus-color{
  color: #ff0000;
}

.el-card__body {
  padding: 10px;
}
.el-dialog__body{
  padding: 10px 20px;
}
</style>