/* eslint-disable */
let host = location.protocol+"//"+location.hostname
let fehost = location.protocol+"//"+location.hostname
let paypalkey = 'AVWSJKm6xyNPFU9A0jtl09uzyO5dgj1-Ho9xvDN6LvCq1R-tYc7BJCdiU9qFqEMDPNf0KxGueT0uDYuy'
let fog = {
  "range": [
      0.5,
      10
  ],
  "color": "#ffffff",
  "high-color": "#245cdf",
  "space-color": [
      "interpolate",
      [
          "linear"
      ],
      [
          "zoom"
      ],
      4,
      "#010b19",
      7,
      "#367ab9"
  ],
  "horizon-blend": [
      "interpolate",
      [
          "linear"
      ],
      [
          "zoom"
      ],
      4,
      0.2,
      7,
      0.1
  ],
  "star-intensity": [
      "interpolate",
      [
          "linear"
      ],
      [
          "zoom"
      ],
      5,
      0.35,
      6,
      0
  ]
}
switch(process.env.NODE_ENV){
  case 'development':{
    host = 'https://timelinegeo.com'
    //host = 'http://127.0.0.1:8090'
    fehost = 'https://timelinegeo.com'
    paypalkey = 'ATbgSiW_16XYOyKgBFBiWrWp_NU7ulaiNhD1TvnqNTEq20lhKl0MqfWSQJKSKNTYLEubfmpBW5G_LBMc'
    break
  }

}

export const Config = {
  host: host,
  fehost: fehost,
  paypalkey: paypalkey,
  fog: fog
}